import * as React from 'react'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import Hero from './Hero'
import GeneralDecorum from './RulesDecorum'
import BannedTechniques from './BannedTechniques'
import RulesGeneral from './RulesGeneral'
import RulesGi from './RulesGi'
import RulesNoGi from './RulesNoGi'
import RuleRespect from './RulesRespect'

function Rules () {
  return (
    <div>
      <Helmet>
        <title>Rules - Lake Oswego, Oregon - The Black Dog Jiu-Jitsu Company</title>
      </Helmet>
      <Box id="rules" className="creme">
        <Hero />
        <RulesGeneral />
        <GeneralDecorum />
        <BannedTechniques />
        <RulesGi />
        <RulesNoGi />
        <Box mb={6}>
          <Divider />
        </Box>
        <RuleRespect />
      </Box>
    </div>
  )
}

export default Rules
