import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
// import { useNavigate } from 'react-router-dom'
// import { HashLink } from 'react-router-hash-link'

import SlideIn from '../../utils/SlideIn'
// import giImage from '../../../assets/standing1.jpg'
// import noGiImage from '../../../assets/ashigarami.jpg'
// import bJJ101Image from '../../../assets/triangle2.jpg'
// import ComingSoonOverlay from '../../utils/ComingSoonOverlay'

import '../../../styles/OtherResources.scss'

function ResourceBlock (props: any) {
  /*
  const navigate = useNavigate()
  const handleClick = (url: string) => {
    navigate(url)
  }
  */

  return (
        <Link href={props.url} sx={{ textDecoration: 'none' }}>
            <Box
                className="resourceBlock"
                p={3}
                mb={2}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} md={9}>
                        <Typography
                            variant="subtitle1"
                            color="primary"
                            sx={{
                              textTransform: 'uppercase',
                              fontWeight: 'bold'
                            }}
                        >{props.title}</Typography>
                        <Typography variant="body1">{props.description}</Typography>
                    </Grid>
                    <Grid item
                        xs={12}
                        md={3}
                        sx={{
                          display: 'flex',
                          alignItems: { md: 'center' },
                          justifyContent: { md: 'end' }
                        }}
                    >
                        <Box
                            p={{ xs: 0, md: 2 }}
                        >
                            <Link href={props.url}>
                                <Typography>Read More &gt;</Typography>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Link>
  )
}

export default function OtherResources () {
  return (
    <Box id="other-resources" className="marginContainer white" py={8}>
      <SlideIn>
        <Container maxWidth="xl" disableGutters={true}>
            <Box>
                <Typography
                    variant="h5"
                    color="primary"
                    mb={3}
                >Other Resources</Typography>
                <ResourceBlock
                    title="Frequently Asked Questions"
                    description="Commonly asked questions and their answers. If you're wondering about something specific, check here first."
                    url="/faq#faq"
                />
                <ResourceBlock
                    title="Rules"
                    description="We've got some! Familiarize yourself with the rules at The Black Dog Jiu-Jitsu Company."
                    url="/rules"
                />
                <ResourceBlock
                    title="Martial Arts"
                    description="An annoying, but short discussion about martial arts at The Black Dog Jiu-Jitsu Company.  We had to write this or else the internet couldn't find us."
                    url="/martial-arts"
                    hidden={true}
                />
            </Box>
        </Container>
      </SlideIn>
    </Box>
  )
}
