import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
// import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export default function SocialMedia () {
  return (
    <Box id="socialmedia" className="marginContainer white" >
      <Container maxWidth="xl" disableGutters={true}>
        <Grid container>
          <Grid item xs={12}>
            <Link href="/">Return to the homepage</Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
