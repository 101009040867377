import React from 'react'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'

import Hero from './hero'
import Discussion from './discussion'
import DiscussionContinued from './discussionContinued'
import SocialMedia from '../../SocialMedia'
import BackToHome from '../../BackToHome'
import MailingList from '../../MailingList'
import StoreAd from '../../StoreAd'
import TheProblem from './theproblem'
// import Closing from './closing'

function Privacy () {
  return (
    <Box id="martial-arts" className="black">
        <Helmet>
          <title>Martial Arts - Lake Oswego, Oregon - The Black Dog Jiu-Jitsu Company</title>
        </Helmet>
        <Hero />
        <TheProblem />
        <Discussion />
        <SocialMedia />
        <DiscussionContinued />
        <BackToHome />
        <MailingList />
        <StoreAd />
    </Box>
  )
}

export default Privacy
