import React from 'react'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'

import Hero from './Hero'
import MembershipOptionsPanel from './MembershipOptions'
import NonMemershipOptionsPanel from './NonMembershipOptions'
import Questions from './Questions'
import Discounts from './Discounts'
import Divider from './Divider'

export default function Welcome () {
  return (
    <div>
      <Helmet>
        <title>Membership Options - Lake Oswego, Oregon - The Black Dog Jiu-Jitsu Company</title>
      </Helmet>
      <Box className="creme">
        <Hero />
        <MembershipOptionsPanel />
        <Divider />
        <NonMemershipOptionsPanel />
        <Questions />
        <Discounts />
      </Box>
    </div>
  )
}
