import React from 'react'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'

import ComingSoonOverlay from '../../utils/ComingSoonOverlay'
import ImageList from './ImageList'
import SlideIn from '../../utils/SlideIn'
import images from './mainArchiveImages'
import data from '../../../data'

export default function Archive () {
  return (
    <div>
      <Helmet>
        <title>Archive - Lake Oswego, Oregon - The Black Dog Jiu-Jitsu Company</title>
      </Helmet>
      <Box id="images" className="creme">
        <Box className="marginContainer">
          <Grid container>
            <Grid item xs={12} md={6}>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="h4" mb={2}>Training at The Black Dog Jiu-Jitsu Company</Typography>
                <Typography variant="body1" mb={1}>Time keeps on slippin' (slippin' slippin') into the future, <Link href="https://www.youtube.com/watch?v=6zT4Y-QNdto" target="_blank">am I right?</Link></Typography>
                <Typography variant="body1" mb={1}>Anyhow, please do us the favor of doom-scrolling through these images. If you were there, you can lament the moments that are now gone.  If you weren't there, maybe take some time to reconsider your life choices.  Or whatever.</Typography>
                <Typography variant="body1">Seriously though, <Link href={data.memberPortal.enrollmentUrl} color="secondary.dark">come train</Link>.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box pb={1}>
          <SlideIn>
            <ComingSoonOverlay>
              <ImageList images={images} />
            </ComingSoonOverlay>
          </SlideIn>
        </Box>
      </Box>
    </div>
  )
}
