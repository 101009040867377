import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'

import ContentBlock from '../../../styles/LeftRightContent'
import mars from '../../../assets/mars.jpg'
import art from '../../../assets/art.png'
import SlideIn from '../../utils/SlideIn'

export default function DiscussionContinued () {
  return (
    <Box id="mars" className="marginContainer creme">
        <SlideIn>
            <Container maxWidth="xl" disableGutters={true}>
                <ContentBlock
                    image={mars}
                    imageAlt="Mars"
                    title="A more permissive interpretation"
                    subtitle=""
                    reverse={true}
                >
                    <Typography variant="body1" my={1}>If we're allowed to take an alternative, more rudimentary definition of the term “martial art”  we're way cooler with it.  And what we mean by that is this:  Let's forget some of the modern connotations and get back to an apprciation of the words that compose the phrase themselves.</Typography>
                    <Typography variant="body1" mb={1}>More specifically, we're really not trying to have argument with the meaning of “martial” - that's actually a fairly cool word.  Fun fact:  the root of the word comes from "Mars" which is the Roman god of war.</Typography>
                    <Typography variant="body1" mb={1}>Digging a little deeper, it just means of or pertaining to war.  Which suits our purposes in Jiu-Jitsu just fine.  Our personal belief is that all sport is a proxy for war and the closer it is to war, the better the sport.</Typography>
                    <Typography variant="body1" mb={1}>So we're left with the word “art”.  And if we take a fundamental definition, we're getting closer to something that were comfortable describing our particular - perhaps idiomatic - mode of training.</Typography>
                </ContentBlock>
            </Container>
        </SlideIn>
        <Box mb={8} mt={4}>
            <Divider />
        </Box>
        <Container maxWidth="xl" disableGutters={true}>
            <ContentBlock
                image={art}
                imageAlt="Art Definition"
                title=""
                subtitle=""
            >
                <Typography variant="body1" mb={1}>In alignment with the definitions provided, "artful" training emphasizes creativity, skill, individuality, imagination and expressiveness.</Typography>
                <Typography variant="h4" my={4}>Jiu-Jitsu at the highest levels reflects the character, unique skills, abilities and physical dispositions of the individual athletes.</Typography>
                <Typography variant="body1" mb={1}>And the ruleset that we practice is so permissive that the ways in which an athlete can move and respond to movement are nearly without limit.  In that broad spectrum of movevent, styles develop and character is revealed.</Typography>
                <Typography variant="body1" mb={1}>Wrapping up:  We don't really have a problem calling ourselves Martial Arts outside of cultural associations.  But as soon as we start getting lumped in with all the McDojos in evey strip mall across America, we're perfectly happy to make a little disctance.</Typography>
            </ContentBlock>
        </Container>
    </Box>
  )
}
