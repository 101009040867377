import * as React from 'react'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import MailingList from '../../MailingList'
import Hero from './Hero'
import Enrollment from './Enrollment'
import Commitment from './Commitment'
import NoteworthyAthletes from './NoteworthyAthletes'
import Gallery from './Gallery'
import CheckTheFAQs from './CheckTheFAQs'
import '../../../styles/YA.scss'

function YoungAdults () {
  return (
    <div>
      <Helmet>
        <title>Young Adults - Lake Oswego, Oregon - The Black Dog Jiu-Jitsu Company</title>
      </Helmet>
      <Box>
        <Hero />
        <Enrollment />
        <Gallery />
        <Commitment />
        <NoteworthyAthletes />
        <CheckTheFAQs />
        <MailingList />
      </Box>
    </div>
  )
}

export default YoungAdults
