import React from 'react'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ReactMarkdown from 'react-markdown'

import membershipAgreement from '../../content/membership-agreement'

function MembershipAgreement () {
  return (
    <div>
      <Helmet>
        <title>Membership Agreement - Lake Oswego, Oregon - The Black Dog Jiu-Jitsu Company</title>
      </Helmet>
      <Box id="membership-agreement" className="marginContainer creme">
        <Container maxWidth="xl" disableGutters={true}>
          <Grid container>
            <Grid item md>
              <Typography variant="h3">Membership Agreement</Typography>
              <ReactMarkdown>
                {membershipAgreement}
              </ReactMarkdown>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default MembershipAgreement
