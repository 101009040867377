import React from 'react'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'

import Hero from './Hero'
import Gear from './Gear'
import MemberPortal from './MemberPortal'
import NextSteps from './NextSteps'
import SocialMedia from '../../SocialMedia'

export default function Welcome () {
  return (
    <div>
      <Helmet>
        <title>Welcome - Lake Oswego, Oregon - The Black Dog Jiu-Jitsu Company</title>
      </Helmet>
      <Box id="images" className="creme">
        <Hero />
        <MemberPortal />
        <SocialMedia />
        <NextSteps />
        <Gear />
      </Box>
    </div>
  )
}
