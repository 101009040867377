import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import ContentBlock from '../../../styles/LeftRightContent'
import tkd from '../../../assets/tkd.jpg'
import SlideIn from '../../utils/SlideIn'

export default function Gi () {
  return (
    <Box
      id="theproblem"
      className="marginContainerTall creme"
    >
      <SlideIn>
        <Container maxWidth="xl" disableGutters={true}>
          <ContentBlock
            image={tkd}
            imageAlt="Nunchaku"
            title="The Problem"
            subtitle="Martial Arts is kinda trash"
          >
            <Typography variant="body1" mb={1}>It's a classic situation:  In polite conversation, it comes up that you've been training Jiu-Jitsu a while and you're excited about the sport, the culture and the athletes (who are now your friends and teammates.)  And then Karen chimes in with something like "Oh, nice.  My niece is 10 and has a black belt in Taekwondo."</Typography>
            <Typography variant="body1" mb={1}>Every jiu-jitsu athlete has been in this one atleast once; beginners may be inclined to dig in and explain the difference to what is probably an uneducated and frankly disinterested audience.  More tenured practitioners are a little more inclined to let the nonsense slide.</Typography>
            <Typography variant="body1" mb={1}>And to be honest, it's not really an issue: It has little or no effect on how our training goes any given day.  But, it does beg some analysis and discussion, even for the simple fact that for many people Jiu-Jitsu is more than a hobby, it becomes a part of who they are.</Typography>
            <Typography variant="body1" mb={1}>So what's the problem?  It's fairly simple:  Martial Arts is a generic term that can be used to describe a fairly wide swath of disciplines, but is in itself poorly defined and is otherwise just non-specific enough to be irksome.</Typography>
            <Typography variant="body1" mb={1}>Because Karen isn't wrong.  We're in a position where our explanation is going to sound like "Yes it's martial arts, but not like those martial arts" (which comes off totally meaningless).  And really what we're doing is trying to dodge associations with negative stereotypes.</Typography>
            <Typography variant="body1" mb={1}>What negative associations? For many "Martial Arts" means bowing, fake tough guys, gratuitous formalities, esoteric nomenclature, strange movements, weird outfits and the ilk.  There's a birthday party Taekwondo place in every mini-mall acrosss America.</Typography>
            <Typography variant="body1" mb={1}>We could go on...</Typography>
          </ContentBlock>
        </Container>
      </SlideIn>
    </Box>
  )
}
