import * as React from 'react'
import { Helmet } from 'react-helmet'
import useMediaQuery from '@mui/material/useMediaQuery'

import MailingList from '../../MailingList'
import SocialMedia from '../../SocialMedia'
import AboutFullDesktop from './AboutFullDesktop'
import AboutFullMobile from './AboutFullMobile'

function About () {
  const smallScreen = !useMediaQuery('(min-width:600px)')

  return (
    <div>
      <Helmet>
        <title>About - Lake Oswego, Oregon - The Black Dog Jiu-Jitsu Company</title>
      </Helmet>
      {
        smallScreen
          ? (<AboutFullMobile />)
          : (<AboutFullDesktop />)
      }
      <SocialMedia />
      <MailingList />
    </div>
  )
}

export default About
